<template>
    <div id="index" ref="appRef">
      <div class="bg">
        <div class="icon_time">
            <img src="../assets/img/ic_tb_time.png" alt="">
            {{ dateYear }} {{dateWeek}} {{ dateDay }}
        </div>

        <div class="title">九赋生活指挥中心</div>
        <img class="title_bj" src="../assets/img/title_bj.png" alt="">
        

            <div class="logon">
                <dv-border-box-12 backgroundColor="rgba(5,27,74,0.73)">
                    <div class="title1">用户登录</div>
                    <div class="logon_con">
                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                            <el-form-item prop="account">
                                <el-input type="text" prefix-icon="el-icon-user-solid" placeholder="用户名" v-model="ruleForm.account" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item prop="passwd">
                                <el-input type="text" prefix-icon="el-icon-lock" show-password placeholder="密码" v-model="ruleForm.passwd" autocomplete="off"></el-input>
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button style="width:100%; height:50px; border-radius:8px; font-size:24px; margin-top:30px;" type="primary" size="medium" @click="submitForm('ruleForm')">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </dv-border-box-12>
            </div>


      </div>
    </div>
  </template>
  
<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js';
import { login } from '../config/httpApi';
import md5 from 'js-md5';
export default {
    mixins: [drawMixin],
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入用户名'));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                callback();
            }
        };
      return {
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],

        ruleForm: {
            account: '',
            passwd: '',
        },
        rules: {
            account: [
            { validator: validatePass, trigger: 'blur' }
          ],
          passwd: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
      }
    },
    mounted() {
        this.timeFn();
    },
    beforeDestroy() {
        clearInterval(this.timing)
    },
    methods: {
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = this.ruleForm;
                    data.passwd = md5(data.passwd);
                    login(data).then(res => {
                        //后端返回的数据
                        console.log(res)
                        if(res.token){
                            localStorage.setItem('token', res.token);
                            this.$router.push({
                                path:"/jFIndex",
                            })
                        }else{
                            this.$message.error('账号或密码错误');
                        }
                    })
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
.bg{
    position: relative;
    .icon_time{
        position: absolute;
        top: 19px; 
        right: 30px;
        img{
            width: 18rpx;
            height: 18px;
            margin-right: 5px;
            float: left;
        }
        font-size: 18px;
        font-weight: bold;
    }
    .title{
        width: 683px;
        height: 42px;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 42px;
        background: url("../assets/img/top_bj.png") no-repeat center;
        background-size: 683px 24px;
        margin: 0 auto 0;
        text-align: center;
    }
    .title_bj{
        width: 1920px;
        height: 27px;

    }

    .logon{
        width: 601px;
        height: 456px;
        // background: rgba(5,27,74,0.73);
        position: absolute;
        left: 50%;
        top: 50%;
        transform:translate(-50%,-50%); /*百分比的参照物是自身*/
        .title1{
            height: 100px;
            font-size: 40px;
            font-weight: 500;
            color: rgba(255,255,255,0.9);
            line-height: 100px;
            text-align: center;
            padding-top: 30px;
        }
        .logon_con{
            width: 400px;
            margin: 44px auto 0;
            .el-input__inner{
                width: 400px;
                height: 60px;
                background: #1C1F52;
                border-radius: 8px;
                font-size: 26px;
                color: #FFFFFF;
                border: 2px solid #136D9F;
                margin-top:10px;
            }
            .el-input__icon{
                font-size: 24px;
            }
            .el-input__prefix, .el-input__suffix{
                top: 7px;
                padding: 0;
            }
        }
    }
}
</style>
  